/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.section--header #home {
  margin-top: 50px;
}
.section--pre-footer #home {
  margin: 40px auto 25px;
}
.c3 .section--header #home {
  margin-top: 20px;
}
.wrapper {
  border-width: 20px;
}
.wrapper:after {
  height: 20px;
}
#signet {
  left: 59px;
  top: 11px;
  width: 55px;
  height: 60px;
}
.vcard {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.38095238;
}
#social .meta {
  height: 61px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#social .meta.service_facebook {
  width: 32px;
  margin-right: 32px;
  background-size: 32px 61px;
}
#social .meta.service_instagram {
  width: 51px;
  background-size: 51px 51px;
}
#cmsbox {
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1;
}
.cb-goto-top {
  height: 118.5px;
  width: 237px;
  border-top-left-radius: 237px;
  border-top-right-radius: 237px;
}
.cb-goto-top:after {
  background-image: url(/images/toplink-large.svg);
  background-size: 8px 64px;
}
.container {
  padding: 60px 0 60px 0;
}
.cb-layout2 .container:not(.container--main) {
  padding-bottom: 40px;
}
.cb-goto-container-next {
  width: 70px;
  height: 70px;
  margin-left: calc(50% - 35px);
  margin-top: 20px;
}
.cb-layout2 .container.container--farwest {
  padding-top: 82px;
  padding-bottom: 0;
  background-size: 52px 52px;
  background-position: center 40px;
}
.cb-layout2 .container.container--farwest .norm {
  font-size: 45px;
  font-size: 4.5rem;
  line-height: 1.04444444;
}
.cb-layout2 .container.container--farwest .line > hr {
  width: 114px;
}
.cb-layout2 .container.container--farwest .unit {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}
.cb-layout2 .container.container--farwest .part {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.wrapper {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.38095238;
}
h1,
h2 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.1875;
}
h3 {
  font-size: 80px;
  font-size: 8rem;
}
.cb-layout1 .container--main h3 {
  font-size: 100px;
  font-size: 10rem;
}
h4 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.16666667;
}
.loud {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.16666667;
}
.pale {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
}
.area .unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
.area .unit .part {
  margin-top: 20px;
  margin-bottom: 20px;
}
input.submit,
button.cb-hybrid {
  min-height: 50px;
}
.unit.cb-album.cb-slideshow .part.foto {
  width: calc(100% - 30px);
  margin: 30px 30px 0 0;
}
.unit.cb-album.cb-slideshow .part.foto:before {
  right: -30px;
  top: -30px;
}
div.load .open,
div.link .open,
div.load .load,
div.link .load {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  display: block;
  padding: 13px 5px;
  border: 1px solid #BA894B;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.22222222;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -605px;
  width: 1210px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */